<!--
 * @Author: 月魂
 * @Date: 2021-03-24 16:15:56
 * @LastEditTime: 2021-03-25 16:57:01
 * @LastEditors: 月魂
 * @Description: 
 * @FilePath: \card\src\views\CouponHolder.vue
-->
<template>
  <div>
    <van-nav-bar
      title="我的券包"
      fixed
      placeholder
      left-arrow
      @click-left="handleBack"
    />
  </div>
</template>

<script>
import Vue from 'vue';
import { NavBar } from 'vant';
Vue.use(NavBar);
export default {
  name: 'CouponHolder',
  methods: {
    handleBack() {
      this.$router.go(-1);
    },
  },
}
</script>
